
const Filter = (props) => (
	<filter id="displacementFilter">
		    <feTurbulence 
		    	class={props.id} 
		    	type="turbulence" 
		    	baseFrequency={props.turbFreq} 
		    	numOctaves={props.octaves} 
		    	result="turbulence" 
		    	style={{transform: "scale(1)"}}>
		    </feTurbulence>
		    <feDisplacementMap 
		    	in2="turbulence" 
		    	in="SourceGraphic" 
		    	scale={props.turbScale} 
		    	xChannelSelector="R" 
		    	yChannelSelector="G">
		    </feDisplacementMap>
		  </filter>
)

const Defs = (props) => (
	<defs>
		<linearGradient id="Gradient1">
	        <stop class="stop1" offset="0%"/>
	        <stop class="stop2" offset="50%"/>
	        <stop class="stop3" offset="100%"/>
	    </linearGradient>
	    <linearGradient id="Gradient2">
	        <stop class="stop4" offset="0%"/>
	        <stop class="stop5" offset="100%"/>
	    
	    </linearGradient>
	</defs>
	)



export const SvgContainer = (props) => {
	let grad = 'url(#Gradient1)'
	if (props.grad == false) {
		grad = 'url(#Gradient2)'
	}

	


	return (
	<div class="svg">
		
		<svg viewBox={"0 0 " + props.w + " " + props.h} preserveAspectRatio="none">
		  <Defs />
		  <Filter 
		  	id={props.id}
		  	turbFreq={props.turbFreq} 
	    	octaves={props.octaves}
	    	turbScale={props.turbScale} />

		  <polygon 
		  	points={props.pointsEnd} 
		  	style={{filter: "url('#displacementFilter')", transform: "scale(1)"}} 
		  	fill={grad}></polygon>
		</svg>
		
  </div>
	)
	}