export const InputView = (props) => (

	<div className={"theInput TOGGLE__theInput--" + props.toggle + " INTENT__theInput--" + props.intent}>
        <input type="text" id="theInput" defaultValue="0" ref={props.theInputRef} />
        <button className="btn btn--max" onClick={props.setInputRefToMAX}>MAX</button>
        <div className="theInput__poolbalance">Pool Balance: <span>{props.poolBalance}</span></div>

        <div className="theInput__balance">Balance: <span>{props.balance}</span></div>
        <div className="theInput__allowance">Allowance: <span>{props.allowance}</span></div>
        <button className="btn btn--back" onClick={props.toggleInput}>back</button>
        <button className="btn btn--approve" onClick={props.onClick_Approve}>Approve This Amount</button>
        <button className="btn btn--deposit" onClick={props.triggerDeposit}>Deposit This Amount</button>
        <button className="btn btn--withdraw" onClick={props.triggerWithdraw}>Withdraw This Amount</button>
      </div>
      )