export function chainMap (id) {
	  if (id === "0x89") return "Matic"
	  if (id === "0xfa") return "Fantom"
	  if (id === "0x38") return "BSC"
	  if (id === "0x1") return "Ethereum"
	  if (id === "0x64") return "xDai"
	  return null
}

export function enforceChain(chainName, callback = () => {}) {
	(chainMap(window.ethereum.chainId) === chainName) ? callback() : alert("Please switch to " + chainName)
}

export function scannerBaseURLByChain (id) {
	if (id === "0x89") return "https://polygonscan.com"
	  if (id === "0xfa") return "https://ftmscan.com"
	  if (id === "0x38") return "https://bscscan.com"
	  if (id === "0x1") return "https://etherscan.com"
	  if (id === "0x64") return "https://blockscout.com/xDai/"
}

export function getZooDexActionLink (action, tokenA, tokenB) {
	const output = "&outputCurrency="
	const input = (action == 'add') ? "add?inputCurrency=" : "market?inputCurrency="
	const token = (tokenA == 'ETH' || tokenA =='FTM') ? getDexNativeSymbol("0xfa","zoo") : tokenA
	if (action == "buy") return "https://trade.zoocoin.cash/trade/" + input + token + output + tokenB
	if (action == "buyDex") return "https://dex.zoocoin.cash/orders/" + input + token + output + tokenB
	if (action == "add") return "https://dex.zoocoin.cash/pool/" + input + token + output + tokenB
	
}

export function getSpiritSwapActionLink ( action, tokenA, tokenB ) {
	const base = "https://swap.spiritswap.finance/#/"
	const input = "/"
	const output = "/"
	if (action == "buy") return base + "swap" + input + tokenA + output + tokenB
	if (action == "add") return base + "add" + input + tokenA + output + tokenB

}

export function getHyperJumpActionLink ( action, tokenA, tokenB ) {
	const base = "https://ftm-farm.hyperjump.fi/"
	const input = "/"
	const output = "/"
	if (action == "buy") return base + "swap" + input + tokenA + output + tokenB
	if (action == "add") return base + "add" + input + tokenA + output + tokenB

}

export function getSushiSwapActionLink ( action, tokenA, tokenB ) {
	const base = "https://app.sushi.com/"
	const input = "/"
	const output = "/"
	if (action == "buy") return base + "swap" + input + tokenA + output + tokenB
	if (action == "add") return base + "add" + input + tokenA + output + tokenB

}

export function getActionLink (id, dex, action, tokenA="FTM", tokenB) {
	if (id === "0xfa") {
		if (dex == "zoo") return getZooDexActionLink(action, tokenA, tokenB)
		if (dex == "spirit") return getSpiritSwapActionLink(action, tokenA, tokenB)
		if (dex == "hyper") return getHyperJumpActionLink(action, tokenA, tokenB)
		if (dex == "sushi") return getSushiSwapActionLink(action, tokenA, tokenB)
			return "#"
	}
}

export function getDexNativeSymbol (id, dex) {
	if (id === "0xfa") {
		if (dex == "zoo") return "FTM"
		if (dex == "spirit") return "FTM"
		if (dex == "hyper") return "FTM"
		if (dex == "sushi") return "ETH"
			return "#"
	}
}