import anime from 'animejs/lib/anime.es.js';


const Filter = (props) => (
	<filter id="displacementFilter">
	    <feTurbulence type="turbulence" baseFrequency="0" numOctaves="2" result="turbulence" style={{transform: 'scale(1)'}}></feTurbulence>
	    <feDisplacementMap in2="turbulence" in="SourceGraphic" scale="1" xChannelSelector="R" yChannelSelector="G"></feDisplacementMap>
	  </filter>
)
const Defs = (props) => (
	<defs>
		<linearGradient id="Gradient1">
	        <stop class="stop1" offset="0%"/>
	        <stop class="stop2" offset="50%"/>
	        <stop class="stop3" offset="100%"/>
	    </linearGradient>

	</defs>
	)



const FancyStuff = (props) => {


	/*var ani = anime({
	  targets: ['.svg polygon', 'feTurbulence', 'feDisplacementMap'],
	  points: props.points,
	  baseFrequency: 0.05,
	  scale: function (el, i, l) {
	  	return (el.tagName === "feDisplacementMap") ? 15 : 1
	  },
	  loop: true,
	  direction: 'alternate',
	  easing: 'easeInOutExpo',
	  duration: 10000
	});*/


	return (
	<div class="svg">
		
		<svg viewBox={"0 0 " + props.w + " " + props.h} width={props.w} height={props.h}>
		<Defs />
		  <filter>
		    <feTurbulence type="turbulence" baseFrequency="0.15" numOctaves="2" result="turbulence" style={{transform: "scale(1)"}}></feTurbulence>
		    <feDisplacementMap in2="turbulence" in="SourceGraphic" scale="15" xChannelSelector="R" yChannelSelector="G"></feDisplacementMap>
		  </filter>
		  <polygon points={props.pointsEnd} style={{filter: "url('#displacementFilter')", transform: "scale(1)"}} fill="url(#Gradient1)"></polygon>
		</svg>
		
  </div>
	)
	}


const p = (x, y) => x + " " + y
const p0 = p(0,0)
const pointArrayRectangle = (xMax, yMax) => {
	return [
		 p(0,0), 
		 p(xMax,0), 
		 p(xMax,yMax), 
		 p(0,yMax)
	]
}
export const DappSection = (props) => {
	var yMax = props.h
	var xMax = props.w
	
	var ps = pointArrayRectangle(xMax, yMax)
	var points = ps.join(" ")
	var psB = ps
	psB[props.morph["p"]] = eval(props.morph["newP"]) 
	var pointsB =  psB.join(" ")
	return (
		<div className="DappSection info">
			{(props.fancy) ? <FancyStuff w={props.w} h={props.h} points={points} pointsEnd={pointsB} /> : ""}
			<h2>Links</h2>
			{props.children}
		</div>

		)
}