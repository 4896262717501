

export const contractConfigs = {
	"generic": {
		"abi": require("../abis/genABI-dai.json"),
		"address": "0x12245af2d6d298D98019C15FB1001D8712D27A7D"
	},
	"chef": {
		"abi": require("../abis/chef.json"),
		"address": "0x070d98f055125eb582922132Cf08090B20B060DD"
	}
}

export const poolConfigs = {
	"0": {
		"stake-name": "wildAnimalZooLP",
		"fee": "1",
		"name": "WILD ANIMAL LP",
		"rate": "0",
		"dex": "zoo",
		"action": "add",
		"status": "closed"
		},
		"1": {
		"stake-name": "acreAnimalZooLP",
		"fee": "1",
		"name": "ACRE ANIMAL LP",
		"rate": "0",
		"dex": "zoo",
		"action": "add",
		"status": "closed"
		},
		"2": {
		"stake-name": "zooAnimalZooLP",
		"fee": "1",
		"name": "ZOO ANIMAL LP",
		"rate": "0",
		"dex": "zoo",
		"action": "add",
		"status": "closed"
		}, 
		"3": {
		"stake-name": "ftmAnimalSpiritLP",
		"fee": "1",
		"name": "FTM-ANIMAL Spirit LP",
		"rate": "1",
		"dex": "spirit",
		"action": "add",
		"status": "open"
		},
		"4": {
		"stake-name": "acreAnimalSpiritLP",
		"fee": "1",
		"name": "ACRE-ANIMAL Spirit LP",
		"rate": "1",
		"dex": "spirit",
		"action": "add",
		"status": "open"
		},
	
	
	

}

export const rewardToken = {
	"name": "animal",
	"displayname": "ANIMAL",
	"symbol": "ANIMAL",
	"address": "0x1f365ac62aabf64ef52a2c2fd86df9dd9e03665f",
	"icon": require("../images/token-icons/animal.png")
}

export const tokenConfigs = {
	"acre": {
		"name": "ACRE",
		"address": "0x12245af2d6d298D98019C15FB1001D8712D27A7D",
		"icon": require("../images/token-icons/acre128.png"),
		"decimals": 18
	},
	"animal": {
		"name": "ANIMAL",
		"address": "0x1f365ac62aabf64ef52a2c2fd86df9dd9e03665f",
		"icon": require("../images/token-icons/animal.png"),
		"decimals": 18
	},
	"gdeli": {
		"name": "gDELI",
		"address": "0xfa1807bd24a438b389ca33072375dfbba74dfaab",
		"icon": require("../images/token-icons/gdeli.png"),
		"decimals": 9
	},
	"echo": {
		"name": "ECHO",
		"address": "0x54477a1d1bb8c1139eef754fd2efd4ddee7933dd",
		"icon": require("../images/token-icons/echo128.png"),
		"decimals": 9
	},
	"ftmGdefHyperLP": {
		"name": "FTM-GIGADEFLATON HLP",
		"address": "0xa8f8ce6959d70dc699d6d603319353547daa3eae",
		"icon": require("../images/token-icons/gigadeflatonftmHLP.png"),
		"decimals": 18
	},
	"ftmDefHyperLP": {
		"name": "FTM-DEFLATON HLP",
		"address": "0x925216d9d3277eb05b009e852be6e37d253d83c6",
		"icon": require("../images/token-icons/deflatonftmHLP.png"),
		"decimals": 18
	},
	"ftmGdefSushiLP": {
		"name": "FTM-GIGADEFLATON SushiLP",
		"address": "0x24623f2a4ec70e81862469fbab10900b51389df1",
		"icon": require("../images/token-icons/gigadeflatonftmSLP.png"),
		"decimals": 18
	},
	"ftmDefSushiLP": {
		"name": "FTM-DEFLATON SushiLP",
		"address": "0x0281596a54d2876e827dc2eeb2102210f204decd",
		"icon": require("../images/token-icons/deflatonftmSLP.png"),
		"decimals": 18
	},
	"catnip": {
		"name": "CATNIP",
		"address": "0xBbd946041824416A4e2EB37d5880718B3812c9Cb",
		"icon": require("../images/token-icons/catnip.png"),
		"decimals": 18
	},
	"ftmHodlZooLP": {
		"name": "FTM-HODL ZooLP",
		"address": "0xdd9ef5358e54faef385fcf27be2ef4b2de469ac4",
		"icon": require("../images/token-icons/hodlftmZLP.png"),
		"decimals": 18
	},
	"ftmGdeliZooLP": {
		"name": "FTM-GDELI ZooLP",
		"address": "0xc2a826d7732bbb5c33768a233de05e78ae274d00",
		"icon": require("../images/token-icons/ftmgdeliZLP.png"),
		"decimals": 18
	},
	"zoo": {
		"name": "Zoo",
		"address": "0x09e145a1d53c0045f41aeef25d8ff982ae74dd56",
		"icon": require("../images/token-icons/ZOO.png"), //./images/token-icons/matic.png"
		"decimals": 0
	},
	"ftmEchoZooLP": {
		"name": "FTM-ECHO ZooLP",
		"address": "0x79d25d00C159BAf1bdE8831B00e2f7370Bf5Fe46",
		"icon": require("../images/token-icons/ftmechoZLP.png"),
		"decimals": 18
	},
	"wildAnimalZooLP": {
		"name": "WILD-ANIMAL ZooLP",
		"address": "0xdbc738aa312b2cdf6471763f56d20544fa97bfb2",
		"icon": require("../images/token-icons/wildanimalzlp.png"),
		"decimals": 18
	},
	"acreAnimalZooLP": {
		"name": "ACRE-ANIMAL ZooLP",
		"address": "0xe3C58E2aaAc97969B8927138ED190907cd0f51B0",
		"icon": require("../images/token-icons/animalacreZLP.png"),
		"decimals": 18
	},
	"zooAnimalZooLP": {
		"name": "ZOO-ANIMAL ZooLP",
		"address": "0x6a9917928a6b2a2d0f891bf1089f7a2138482fce",
		"icon": require("../images/token-icons/animalzooZLP.png"),
		"decimals": 18
	},
	"ftmAnimalSpiritLP": {
		"name": "FTM-ANIMAL Spirit LP",
		"address": "0x4a3413f95110400b7c8f08378456b94e2c52989c",
		"icon": require("../images/token-icons/ftmanimalzlp.png"),
		"decimals": 18
	},
	"acreAnimalSpiritLP": {
		"name": "ACRE-ANIMAL Spirit LP",
		"address": "0x1f0d6f053b58ef9c28733aee0ed5324d084a26a5",
		"icon": require("../images/token-icons/animalacreZLP.png"),
		"decimals": 18
	},
}

//0x1f0d6f053b58ef9c28733aee0ed5324d084a26a5